
import { loadStripe } from "@stripe/stripe-js";
import { defineComponent, ref, onBeforeMount, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import objectPath from "object-path";

export default defineComponent({
  name: "Checkout",
  data() {
    return {
      viewbtn: true,
    };
  },
  setup() {
    var store = useStore();
    const router = useRouter();
    const route = useRoute();
    let currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const config = computed(() => {
      return store.getters[Modules.APP + "getConfig"];
    });
    const checkout = computed(() => {
      return store.getters[Modules.PLAN + "getCheckout"];
    });
    const stripeLoaded = ref(false);

    onBeforeMount(() => {
      loadStripeNow();
    });

    watch(config, async () => {
      loadStripeNow();
    });

    const loadStripeNow = async () => {
      if (config.value.stripe_key && !stripeLoaded.value) {
        const stripe = await loadStripe(config.value.stripe_key);
        // stripe.then(() => {
        //   stripeLoaded.value = true;
        // });
        store
          .dispatch(Modules.PLAN + Actions.FETCH_CHECKOUT, {
            plan_code: route.params.code,
          })
          .then(() => {
            return stripe?.redirectToCheckout({ sessionId: checkout.value.id });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters[Modules.PLAN + "getErrors"]);
            Swal.fire({
              text: store.getters[Modules.PLAN + "getErrors"][error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Go to Dashboard!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            }).then(function () {
              router.push({ name: "dashboard" });
            });
          });
      }
    };

    return {
      config,
      stripeLoaded,
      currentUser,
    };
  },
});
